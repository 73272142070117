// 招商帮扶
<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'

export default {
  name: 'investment',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/config/farmConfigInvest/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          key: 'name',
          placeholder: '请输入招商帮扶',
        },
        {
          name: '公告类型',
          type: 'select',
          key: 'investType',
          typeData: [
            {
              name: '平台公告',
              value: '0',
            },
            {
              name: '文章推荐',
              value: '1',
            },
            {
              name: '数字农业',
              value: '2',
            },
            {
              name: '乡村节庆',
              value: '3',
            },
            {
              name: '节气游乡村',
              value: '4',
            },
            {
              name: '非遗传承',
              value: '5',
            },
          ],
        },
        {
          name: '发布时间',
          type: 'rangePicker',
          placeholder: '请选择新闻发布日期',
          keys: ['beginDate', 'endDate'],
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'name',
          title: '公告标题',
          filter: true,
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length,
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                <img src={records.imgUrl} />
                <div>{text}</div>
              </div>
            )
          },
          //   onExport: (text, records) => {
          //     return `${records.imgUrl} / ${text}`
          //   }
        },
        {
          title: '公告类型',
          dataIndex: 'investType',
          align: 'left',
          customRender: (text) => {
            return ['平台公告', '文章推荐', '数字农业', '乡村节庆', '节气游乡村', '非遗传承'][+text]
          },
        },
        {
          dataIndex: 'createTime',
          title: '发布时间',
          align: 'left',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix(),
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => this.$router.push(`/platform/investmentDetail?id=${records.id}`),
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除吗?',
                onClick: () =>
                  api.command.delPost.call(this, {
                    url: `/config/farmConfigInvest/delete?id=${records.id}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          icon: 'plus',
          type: 'primary',
          onClick: () => this.$router.push('/platform/investmentDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let ids = data.map((e) => e.id)
                return api.command.delPost.call(this, {
                  url: `/config/farmConfigInvest/deleteBatch?ids=${ids}`,
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
